let RoutesData = [{
    "title": "Home",
    "route": ""
// }, {
//     "title": "Connect",
//     "route": "connect"
// }, {
//     "title": "Disconnect",
//     "route": "disconnect"
// }, {
//     "title": "Registration",
//     "route": "registration"
// }, {
//     "title": "Publish",
//     "route": "publish"
// }, {
//     "title": "Reports",
//     "route": "report"
}, {
    "title": "Our Technologies",
    "route": "technology"
// }, {
//     "title": "News",
//     "route": "updates"
// }, {
//     "title": "Shop",
//     "route": "shop"
// }, {
//     "title": "Account",
//     "route": "account"
}, {
    "title": "ISO Registration",
    "route": "iso"
}, {
    "title": "Contact",
    "route": "contact"
}, {
    "title": "Copyright",
    "route": "copyright"
}, {
    "title": "Terms",
    "route": "terms"
// }, {
//     "title": "Privacy",
//     "route": "privacy"
// }, {
//     "title": "Security",
//     "route": "security"
// }, {
//     "title": "Cookie",
//     "route": "cookie"
}, {
    "title": "Disclaimer",
    "route": "disclaimer"
}, {
    "title": "PageNotFound",
    "route": "*"
}]

export default RoutesData