import React, { Component } from 'react';
import ConnectPageComponent from '../Pages/Auth/Connect'
import DisconnectPageComponent from '../Pages/Auth/Disconnect'
import RegistrationPageComponent from '../Pages/Auth/Registration'
import HomePageComponent from '../Pages/Home'
import AccountPageComponent from '../Pages/Auth/Account'
import PublishPageComponent from '../Pages/App/Publish'
import ReportPageComponent from '../Pages/App/Reports'
import ShopPageComponent from '../Pages/App/Shop'
import TechnologiesPageComponent from '../Pages/App/Technologies'
import UpdatesPageComponent from '../Pages/App/Updates'
import ContactPageComponent from '../Pages/Support/Contact'
import HelpPageComponent from '../Pages/Support/Help'
import CookiePageComponent from '../Pages/Legal/Cookie'
import CopyrightPageComponent from '../Pages/Legal/Copyright'
import PrivacyPageComponent from '../Pages/Legal/Privacy'
import SecurityPageComponent from '../Pages/Legal/Security'
import TermsPageComponent from '../Pages/Legal/Terms'
import DisclaimerPageComponent from '../Pages/Legal/Disclaimer'
import PageNotFoundComponent from '../../../Shared/Error/PageNotFound'

class RoutesComponent extends Component {
    render() {
        const { page } = this.props
        return (
            <div className="xc-route-component-content">
                { page === "connect" ? <ConnectPageComponent /> : <></> }
                { page === "disconnect" ? <DisconnectPageComponent /> : <></> }
                { page === "registration" ? <RegistrationPageComponent /> : <></> }
                { page === "" ? <HomePageComponent /> : <></> }
                { page === "account" ? <AccountPageComponent /> : <></> }
                { page === "publish" ? <PublishPageComponent /> : <></> }
                { page === "report" ? <ReportPageComponent /> : <></> }
                { page === "technology" ? <TechnologiesPageComponent /> : <></> }
                { page === "updates" ? <UpdatesPageComponent /> : <></> }
                { page === "shop" ? <ShopPageComponent /> : <></> }
                { page === "contact" ? <ContactPageComponent /> : <></> }
                { page === "iso" ? <HelpPageComponent /> : <></> }
                { page === "cookie" ? <CookiePageComponent /> : <></> }
                { page === "copyright" ? <CopyrightPageComponent /> : <></> }
                { page === "privacy" ? <PrivacyPageComponent /> : <></> }
                { page === "security" ? <SecurityPageComponent /> : <></> }
                { page === "terms" ? <TermsPageComponent /> : <></> }
                { page === "disclaimer" ? <DisclaimerPageComponent /> : <></> }
                { page === "*" ? <PageNotFoundComponent /> : <></> }
            </div>
        )
    }
}
export default RoutesComponent