import React, { Component } from 'react';

class HelpPageComponent extends Component {
    render() {
        return (
            <div>
                <br/><br/>
                <p> &emsp; For More Details &nbsp; : &nbsp; 
                    <a style={{color: "blue"}} href='https://www.iafcertsearch.org/certification/34GGDB8aRiOd0UfYMnS9XbTT' target="_blank" rel="noopener noreferrer">
                        <i>ISO Accredited Details</i>
                    </a>
                </p>
            </div>
        )
    }
}
export default HelpPageComponent