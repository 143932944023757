import React, { Component } from 'react';
import TechnologiesData from '../../../Model/Technology/Technologies';
import GetImageURL from '../../../Utils/GetImageURL';

class HomePageComponent extends Component {
    render() {
        let technologiesdata = TechnologiesData;
        return (
            <div>
                <div className="card text-center">
                    <div className="card-body xc-card-body">
                        <img className="img-fluid text-center" width="500px" height="500px" src={GetImageURL("Head")} alt="Categories" />
                        <br/>
                        <h1>ᴇᴍᴘᴏᴡᴇʀɪɴɢ ʙᴜꜱɪɴᴇꜱꜱᴇꜱ ᴡɪᴛʜ ᴄᴜᴛᴛɪɴɢ-ᴇᴅɢᴇ ᴛᴇᴄʜɴᴏʟᴏɢʏ & ᴇxᴘᴇʀᴛɪꜱᴇ</h1>
                        <br/>
                        <h4>ꜱᴏꜰᴛᴡᴀʀᴇ ᴛᴇᴄʜɴᴏʟᴏɢʏ ᴅᴇᴠᴇʟᴏᴘᴍᴇɴᴛ</h4>
                        <h4>ʙ2ʙ & ʙ2ᴄ ɪᴛ ꜱᴇʀᴠɪᴄᴇꜱ</h4>
                        <h4>ɪɴᴛᴇʀᴀᴄᴛɪᴠᴇ ᴀᴄᴀᴅᴇᴍɪᴄ ꜱᴇʀᴠɪᴄᴇꜱ</h4>
                        <h4>ɪɴᴠᴇꜱᴛᴍᴇɴᴛꜱ ᴀɴᴅ ꜰɪɴᴀɴᴄɪᴀʟ ᴀꜱꜱᴇᴛꜱ ᴍᴀɴᴀɢᴇᴍᴇɴᴛ</h4>
                        <h4>ɢᴀᴍɪɴɢ ᴛᴇᴄʜɴᴏʟᴏɢʏ ᴅᴇᴠᴇʟᴏᴘᴍᴇɴᴛ</h4>
                        <br/>
                        <img className="img-fluid text-center" width="500px" height="500px" src={GetImageURL("Verified")} alt="Categories" />
                    </div>
                </div>
                <br/><br/>
                <div className="card-body text-center mx-auto p-2 w-75">
                    <h3 className="text-center">Our Mission, Our Vision</h3>
                    <br/>
                    <p className="card-text">
                        Our vision is to be the cornerstone of innovation in core and mainframe technology, driving the evolution of futuristic solutions that empower businesses to excel in an ever-changing world. We are dedicated to developing cutting-edge technologies that form the backbone of advanced business applications. From interactive academic learning platforms to investment, financial, and asset management solutions, we aim to streamline processes and enhance value. Our expertise also spans robust B2B and B2C applications, as well as dynamic gaming technologies that redefine user engagement and performance. By embracing innovation and foresight, we envision a future where businesses harness technology to unlock limitless potential, transform industries, and create meaningful impacts on the global stage.
                    </p>
                </div>
                <br/><br/><br/>
                <h3 className="text-center">Our Technologies & Services</h3>
                <br />
                {
                    technologiesdata.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="card text-center mx-auto p-2 w-75 border border-success xc-home-tech-section">
                                    <div className="card-header"><strong>{item.type}</strong></div>
                                    <div className="card-body">
                                        <h5 className="card-title">{item.title}</h5>
                                        <p className="card-text">{item.description}</p>
                                        <a href={item.url} className="btn btn-success xc-btn" target="_blank" rel="noopener noreferrer">Connect &nbsp; <i className="fa fa-wifi"></i></a>
                                    </div>
                                </div>
                                <br/>
                            </div>
                        )
                    })
                }
                <br/><br/>
                <div id="xccarousel" className="carousel slide mb-6 xc-card-body" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                    <button type="button" data-bs-target="#xccarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#xccarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#xccarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="rgb(0, 57, 9)"/></svg>
                            <div className="container">
                                <div className="carousel-caption">
                                    <h5><strong>Innovative Solutions for Business Success</strong></h5>
                                    <a href="technology" className="btn btn-success xc-btn">Learn More &nbsp; <i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="rgb(0, 57, 9)"/></svg>
                            <div className="container">
                                <div className="carousel-caption">
                                    <h5><strong>Leading with Technology and Expertise</strong></h5>
                                    <a href="technology" className="btn btn-success xc-btn">Learn More &nbsp; <i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="rgb(0, 57, 9)"/></svg>
                            <div className="container">
                                <div className="carousel-caption">
                                    <h5><strong>Revolutionizing Business with Expert Technology</strong></h5>
                                    <a href="technology" className="btn btn-success xc-btn">Learn More &nbsp; <i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#xccarousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#xccarousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <br />
            </div>
        )
    }
}
export default HomePageComponent