let SocialMediaLinksData = [{
    "url": "https://www.facebook.com/xcolon.in",
    "classname": "fa fa-facebook-f xc-footer-social-link"
}, {
    "url": "https://www.instagram.com/__xcolon__",
    "classname": "fa fa-instagram xc-footer-social-link"
}, {
    "url": "https://www.youtube.com/@xcolon",
    "classname": "fa fa-youtube-play xc-footer-social-link"
}, {
    "url": "https://www.linkedin.com/in/xcolon-opc-private-limited-36ab892b2",
    "classname": "fa fa-linkedin xc-footer-social-link"
}, {
    "url": "https://x.com/xcolon_tm",
    "classname": "fa fa-twitter xc-footer-social-link"
}, {
    "url": "mailto:info@xcolon.org",
    "classname": "fa fa-google xc-footer-social-link"
}, {
    "url": "https://github.com/xcolon-official",
    "classname": "fa fa-github xc-footer-social-link"
}, {
    "url": "https://stackoverflow.com/users/23131329/xcolon",
    "classname": "fa fa-stack-overflow xc-footer-social-link"
}, {
    "url": "https://t.me/xcolon_official",
    "classname": "fa fa-telegram xc-footer-social-link"
// }, {
//     "url": "https://whatsapp.com/channel/0029Vagt2YlBA1f4FNzni20g",
//     "classname": "fa fa-whatsapp xc-footer-social-link"
}]

export default SocialMediaLinksData